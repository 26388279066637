// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDgzLv5nts6yTBR48SwUmZ7p_TbnZWr7Gw",
    authDomain: "portfolio-1d168.firebaseapp.com",
    databaseURL: "https://portfolio-1d168.firebaseio.com",
    projectId: "portfolio-1d168",
    storageBucket: "portfolio-1d168.appspot.com",
    messagingSenderId: "591804974185",
    appId: "1:591804974185:web:b8af4a3081372492c80ef8",
    measurementId: "G-GHVLJ1NEX5"
  }
};
