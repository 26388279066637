import { Component, OnInit } from '@angular/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/me', title: 'Dorian West Not Gray',  icon: 'users_single-02', class: '' },
    { path: '/skills', title: 'Skills',  icon:'ui-2_settings-90', class: '' },
    { path: '/projects', title: 'Projects',  icon:'tech_laptop', class: '' },
    { path: '/resume', title: 'Resume',  icon:'business_briefcase-24', class: '' },

    // { path: '/user-profile', title: 'Contact',  icon:'users_single-02', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'design_bullet-list-67', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'text_caps-small', class: '' },
    { path: '/pay', title: 'Pay Me!!',  icon:'business_money-coins', class: 'active active-pro' }

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
